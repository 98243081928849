import Vue from 'vue';
import axios from "axios";
import store from "@/store";
import {
  Message
} from 'element-ui';
//  const baseUrl = config.baseUrl;
// const baseUrl = "http://58.18.32.146:17063"
// const baseUrl = "http://58.18.32.146:17063"
// const baseUrl = "/apis"
// const baseUrl = "http://operatecode.mycelnet.net"
const baseUrl = "http://acquisitioncode.mycelnet.net"
axios.defaults.withCredentials = true; //让ajax携带cookie
class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.queue = {};
  }
  //默认配置公共参数
  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {},
    };
    return config;
  }
  //根据code 执行不通操作
  codeState(data) {
    //当返回的是
    if (typeof data === 'string') return {
      code: 200,
      msg: '成功',
      body: data
    };
    let {
      code,
      msg
    } = data;
    if (parseInt(code) === 200) {
      data.msg = "成功";
      return data;
    } else if (code === 223) {
      //登录失效，需要重新登录
      //清除token,清除页面权限
      // Vue.prototype.$cookies.remove('account')
      // Vue.prototype.$cookies.remove('usertoken')
      store.commit("setUserInfo", '');
      if (document.getElementsByClassName('el-message').length <= 0) {
        console.log(111, Message);
        Message({
          message: '该账号没有此权限',
          type: 'error',
          showClose: true,
          duration: 8000,
          onClose: () => {
            //跳转到菌网登录页面
            // window.location.href = 'http://58.18.32.146:17032';
          }
        });
      }
    } else if (code === 217) {
      //登录失效，需要重新登录
      //清除token,清除页面权限
      Vue.prototype.$cookies.remove('account')
      Vue.prototype.$cookies.remove('usertoken')
      store.commit("setUserInfo", '');
      if (document.getElementsByClassName('el-message').length <= 0) {
        Message.error({
          message: '登录已失效，需要重新登录',
          type: 'success',
          showClose: true,
          duration: 8000,
          onClose: () => {
            //跳转到菌网登录页面
            // window.location.href = 'http://58.18.32.146:17032';
          }
        });
      }
    } else if (code === 500) {
      return data;
    } else {
      return data;
    }
  }

  // 请求拦截
  interceptors(instance, url) {
    instance.interceptors.request.use(
      config => {
        console.log('config', config);
        if (Vue.prototype.$cookies.get('usertoken')) {
          let token = Vue.prototype.$cookies.get('usertoken');
          console.log('token', token);
          config.headers['token'] = Vue.prototype.$cookies.get('usertoken');
          // config.headers['token'] = localStorage.getItem('Token');
        }
        if (config.responseType && config.responseType == 'blob') {
          config.headers['content-type'] == 'application/octet-stream;charset=UTF-8'
        }
        return config;
      },
      error => {
        return Proimse.reject(error);
      }
    );
    // 响应拦截
    instance.interceptors.response.use(
      res => {
        const {
          data,
          status
        } = res;
        let userToken = res.headers.token
        if (userToken) {
          Vue.prototype.$cookies.remove('usertoken')
          if (base == 'prod') { //正式环境区别处理
            Vue.prototype.$cookies.set('usertoken', userToken, null, null, '.mycelnet.net')
          } else {
            Vue.prototype.$cookies.set('usertoken', userToken)
          }
        }
        if (status == 200) return Promise.resolve(this.codeState(data)); //状态操作
        else return Promise.reject(data);
      },
      error => {
        let errorInfo = error.response;
        if (!errorInfo) {
          const {
            request: {
              status
            },
            config
          } = JSON.parse(JSON.stringify(error));
          errorInfo = {
            status,
            request: {
              responseURL: config.url
            }
          };
        }
        return Promise.reject(error);
      }
    );
  }

  //抛出调用方法
  request(options) {
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance, options.url);
    return instance(options);
  }
}

const feach = new HttpRequest(baseUrl);
export default feach;