/**
 * 文件描述: 日志路由文件
 * @author 阿苏
 * @dTime  2022/09/14
 * @see    
 */
import Layout from '@/layout/layout.vue'
const routes = [
  {
    path: '/log',
    name: 'log',
    component: Layout,
    dispatch: '/list',
    meta: {
      showMenus: true,
      levelMenus: 1,
      icon: "el-icon-date",
      menuId: 9,
      title: "日志管理"
    },
    children: [
      // 权限管理-管理员列表
      {
        path: 'list',
        name: 'list',
        meta: {
          showMenus: true,
          levelMenus: 2,
          menuId: 12,
          parentName: 'log',
          fromName: "list",
          title: "日志列表"
        },
        component: () => import('../../views/log/logList.vue'),
      },
    ]
  },
]
export default routes