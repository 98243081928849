import { setToken, getToken, removeToken } from "@/utils/util";

export default {
  state: {
    userInfo: {},
    token: getToken()
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    setToken(state, token) {
      state.token = token;
      if (token) setToken(token);
      else removeToken();
    }
  },
  actions: {
    
  }
};
