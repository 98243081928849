/**
 * 文件描述: 权限管理路由文件
 * @author 阿苏
 * @dTime  2022/09/14
 * @see    
 */
import Layout from '@/layout/layout.vue'
const routes = [{
  path: '/authorityManage',
  name: 'authorityManage',
  component: Layout,
  dispatch: '/list',
  meta: {
    showMenus: false,
    levelMenus: 1,
    icon: "el-icon-key",
    menuId: 2,
    title: "权限管理"
  },
  children: [
    // 权限管理-管理员列表
    {
      path: 'managerList',
      name: 'managerList',
      meta: {
        showMenus: true,
        levelMenus: 2,
        parentName: 'authorityManage',
        fromName: "managerList",
        menuId: 5,
        title: "管理员列表"
      },
      component: () => import('../../views/authorityManage/managerList.vue'),
    },
    // 权限管理-角色管理
    {
      path: 'roleManage',
      name: 'roleManage',
      meta: {
        showMenus: true,
        levelMenus: 2,
        parentName: 'authorityManage',
        fromName: "roleManage",
        menuId: 4,
        title: "角色管理"
      },
      component: () => import('../../views/authorityManage/roleManage.vue'),
    },
  ]
}, ]
export default routes