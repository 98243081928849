<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="(item,index) in breadcrumbList" :key="index" :to='{path:item.path,query:item.query}'
        class="secondTitle" :replace='true'>{{ item.name }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'AcquisitionsystemBreadcrumb',

  data () {
    return {
      breadcrumbList: [],
      breadcrumbTitleList: [],
    };
  },
  watch: {
    //监听路由修改面包屑
    $route: {
      handler (newName) {
        // console.log('newName', newName);
        // console.log('breadcrumbList', this.breadcrumbList);
        // console.log('breadcrumbTitleList', this.breadcrumbTitleList);
        if (
          this.breadcrumbList.length != 0 &&
          this.breadcrumbList[0].pName != newName.meta.parentName
        ) {
          this.breadcrumbList = [];
          this.breadcrumbTitleList = [];
        } else if (this.breadcrumbTitleList.includes(newName.meta.title)) {
          let index = this.breadcrumbTitleList.indexOf(newName.meta.title);
          this.breadcrumbList.splice(index, 1);
          this.breadcrumbTitleList.splice(index, 1);
        }

        this.breadcrumbTitleList.push(newName.meta.title);
        this.breadcrumbList.push({
          name: newName.meta.title,
          path: newName.path,
          pName: newName.meta.parentName,
          query: newName.query,
        });
      },
      immediate: true,
    },
  },
  mounted () {

  },

  methods: {

  },
};
</script>

<style lang="scss" scoped>

</style>