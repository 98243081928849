import Layout from '@/layout/layout.vue'
const routes = [
  {
    path: '/taskCenter',
    name: 'taskCenter',
    component: Layout,
    dispatch: '/list',
    meta: {
      showMenus: true,
      levelMenus: 1,
      icon: "el-icon-set-up",
      menuId: 1,
      title: "任务中心"
    },
    children: [
      // 任务中心-任务列表
      {
        path: 'list',
        name: 'list',
        meta: {
          showMenus: true,
          levelMenus: 2,
          parentName: 'taskCenter',
          fromName: "list",
          menuId: 3,
          title: "任务列表 (临时任务)"
        },
        component: () => import('../../views/taskCenter/list.vue'),
      },
      // 任务列表-详情
      {
        path: 'details/:taskName',
        name: 'taskCenterDetails',
        meta: {
          showMenus: false,
          levelMenus: 2,
          parentName: 'taskCenter',
          fromName: "list",
          menuId: 3,
          title: "任务列表详情"
        },
        component: () => import('../../views/taskCenter/details.vue'),
      },
      // 任务中心-新建任务
      {
        path: 'addTask',
        name: 'addTask',
        meta: {
          showMenus: false,
          levelMenus: 2,
          parentName: 'taskCenter',
          fromName: "list",
          title: "新建任务"
        },
        component: () => import('../../views/taskCenter/addTask.vue'),
      },
      // 任务中心-任务结果
      {
        path: 'taskResult',
        name: 'taskResult',
        meta: {
          showMenus: false,
          levelMenus: 2,
          parentName: 'taskCenter',
          fromName: "list",
          title: "任务结果"
        },
        component: () => import('../../views/taskCenter/taskResult.vue'),
      },
      // 任务中心-历史任务
      {
        path: 'historyAcquisition',
        name: 'historyAcquisition',
        meta: {
          showMenus: false,
          levelMenus: 2,
          parentName: 'taskCenter',
          fromName: "list",
          title: "历史任务"
        },
        component: () => import('../../views/taskCenter/historyAcquisition.vue'),
      },
    ]
  },
]
export default routes