import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/layout.vue'
import RaskCenter from "./menus/taskCenter"
import AcquisitionBoard from "./menus/acquisitionBoard"
import AuthorityManage from "./menus/authorityManage"
import Log from "./menus/log"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    meta: {
      showMenus: false,
      levelMenus: 0,
      icon: "ios-card-outline",
      menuId: 0,
      title: "首页"
    },
  },
  // 任务中心
  ...RaskCenter,
  // 采集器看板
  ...AcquisitionBoard,
  // 权限管理
  ...AuthorityManage,
  // 日志管理
  ...Log
]

const router = new VueRouter({
  routes
})

export default router
