
/**
 * 文件描述: 任务中心API接口
 * @author 阿苏
 * @dTime  2022/09/15
 * @see    
 */
import feach from "../axios";
const APIS = {
  TaskList: "/taskCenter/list",
  Token: '/getToken',
  DeleteTask: "/taskCenter/del",
  TemplateList: "/taskCenter/moduleList", // 模版列表
  Example: "/taskCenter/exampleData", // 事例数据
  UploadingData: "/taskCenter/uploadParam",// 上传数据
  DownloadTemplatData: "/moduleExcel",// 下载模版数据
  DownloadData: "/taskCenter/exportExcel",// 下载数据
  AddTask: "/taskCenter/add",
  ResultTableName: "/taskCenter/resultTable", // 结果数据表名
  ResultTableData: "/taskCenter/resultData", // 结果数据
  ActiveTask: "/taskCenter/operate" //操作任务
}
/**
 * @description 获取品类管理列表
 * @param 
 */
export const getTaskList = params => {
  return feach.request({
    url: APIS.TaskList,
    method: "GET",
    params
  });
};
/**
 * @description 获取品类管理列表
 * @param 
 */
export const getToken = params => {
  return feach.request({
    url: APIS.Token,
    method: "GET",
    params
  });
};
/**
 * @description 删除临时任务
 * @param 
 */
export const deleteTask = params => {
  return feach.request({
    url: `${APIS.DeleteTask}/${params.taskName}`,
    method: "DELETE",
  });
};
/**
 * @description 获取模版列表
 * @param 
 */
export const getTemplateList = params => {
  return feach.request({
    url: APIS.TemplateList,
    method: "GET",
  });
};
/**
 * @description 获取模版事例
 * @param 
 */
export const getExample = params => {
  return feach.request({
    url: `${APIS.Example}/${params.taskName}`,
    method: "GET",
  });
};
/**
 * @description 下载数据
 * @param 
 */
export const downloadData = data => {
  return feach.request({
    url: `${APIS.DownloadData}/${data.taskName}/${data.tableName}`,
    method: "get",
    responseType: "blob"
  });
};
/**
 * @description 上传数据
 * @param 
 */
export const uploadingData = data => {
  return feach.request({
    url: APIS.UploadingData,
    method: "POST",
    data
  });
};
/**
 * @description 添加任务
 * @param 
 */
export const addTask = data => {
  return feach.request({
    url: APIS.AddTask,
    method: "POST",
    data
  });
};
/**
 * @description 获取结果数据表名
 * @param 
 */
export const getResultTableName = params => {
  return feach.request({
    url: `${APIS.ResultTableName}/${params.taskName}`,
    method: "get",
  });
};
/**
 * @description 获取结果数据
 * @param 
 */
export const getResultTableData = params => {
  return feach.request({
    url: `${APIS.ResultTableData}/${params.taskName}/${params.tableName}`,
    method: "get",
    params
  });
};
/**
 * @description  操作任务
 * @param 
 */
export const activeTask = data => {
  return feach.request({
    url: `${APIS.ActiveTask}/${data.taskName}/${data.taskOperate}`,
    method: "POST",
  });
};
/**
 * @description  操作任务
 * @param 
 */
export const downloadTemplatData = data => {
  return feach.request({
    url: `${APIS.DownloadTemplatData}/${data.taskName}.xlsx`,
    method: "POST",
  });
};
