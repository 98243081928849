import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";
// import SecureLS from "secure-ls";
import user from "./module/user";

Vue.use(Vuex);

// const ls = new SecureLS({
//   encodingType: "aes",
//   isCompression: false,
//   encryptionSecret: "operationSystem-admin"
// });

export default new Vuex.Store({
  state: {
    menusId: {}
  },
  mutations: {
    setMenusId(state, resa) {
      // console.log('state',state);
      // console.log('resa',resa);
      sessionStorage.setItem('role', JSON.stringify(resa))
      state.menusId = resa
    }
  },
  actions: {},
  modules: {
    user,
  },
  plugins: [
    // createPersistedState({
    //   key: "operationSystem-admin",
    //   storage: {
    //     getItem: key => ls.get(key),
    //     setItem: (key, value) => ls.set(key, value),
    //     removeItem: key => ls.remove(key)
    //   }
    // })
  ]
});
