<template>
  <el-container>
    <el-header height="80px">
      <h1 class="title">采集运营系统</h1>
      <div class="user-info">
        <el-avatar icon="el-icon-user-solid"></el-avatar>
        <div class="name">{{ userName }}</div>
      </div>
    </el-header>
    <el-container>
      <el-aside>
        <NavMenu :menuList="menuList"></NavMenu>
      </el-aside>
      <el-main>
        <Breadcrumb></Breadcrumb>
        <div class="content">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import NavMenu from './components/navMenu.vue'
import { getMenusList } from "../http/api/home"
import Breadcrumb from './components/Breadcrumb.vue'
export default {
  name: "AcquisitionsystemLayout",

  data () {
    return {
      userName: "",
      menuList: [],

    };
  },
  components: {
    NavMenu,
    Breadcrumb
  },
  created () {
    this.getMenusList()
  },
  mounted () { },

  methods: {
    /**
 * @description 获取菜单导航
 * @param 
 */
    async getMenusList () {
      // try {
      // let { code, body } = await getMenusList()
      // if (code == 200) {
      // this.userName = body.isManager == 1 ? "超级管理员" : "普通管理员";
      //   if (JSON.stringify(body) != "{}") {
      let routers = this.$router.options.routes;
      this.menuList = this.disposeTreeData(routers, { isManager: 1 });
      //     }
      //   }
      // } catch (error) { }
    },
    /**
* @description 重新整合用户的可访问的菜单
* @param
*/
    disposeTreeData (city, id) {
      let item = [];
      city.map((res) => {
        if (
          res.meta.showMenus &&
          (id.isManager == 1 ? true : id["menus"].includes(res.meta.menuId))
        ) {
          let newObj = { ...res };
          if (res.children) {
            newObj.children = this.disposeTreeData(res.children, id);
          }
          item.push(newObj);
        }
      });
      return item;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-header {
  text-align: center;
  height: 80px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 28px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #507CE5;
  }
}

.el-aside {
  background-color: #FFFFFF;
  color: #333;
  text-align: center;
  width: auto !important;
  overflow: visible;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  padding: 20px 26px;
  display: flex;
  flex-direction: column;
}

body>.el-container {
  margin-bottom: 40px;
  height: 100vh;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.title {
  font-size: 28px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #FFFFFF;
}

.content {
  flex: 1;
  background: #fff;
  margin-top: 20px;
}

.user-info {
  display: flex;
  align-items: center;
  margin-right: 20px;

  .name {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #507CE5;
    margin-left: 20px;
  }
}
</style>