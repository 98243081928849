<!-- 文件描述: 页面菜单导航 -->
<!-- 创建时间: 2022/09/14；创建人: 阿苏 -->
<!-- 最后修改时间: 2022/09/14；最后修改人: 阿苏-->

<template>
  <div class="home-nav-menu">
    <el-radio-group v-model="isCollapse">
      <i class="el-icon-s-fold" v-show="!isCollapse" @click="isCollapse=true"></i>
      <i class="el-icon-s-unfold" v-show="isCollapse" @click="isCollapse=false"></i>
    </el-radio-group>
    <div style="height:40px"></div>
    <el-menu class="el-menu-vertical-demo" :router="true" :collapse="isCollapse" :default-active="activeName"
      :default-openeds="openName">
      <el-submenu v-for="(item,index) in menuList" :key="index" :index="item.name">
        <template slot="title">
          <i :class="item.meta.icon"></i>
          <span slot="title">{{item.meta.title}}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item v-for="(res,k) in  item.children" :key="k" :index="item.path+'/'+res.path">{{res.meta.title}}
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
  
<script>
export default {
  props: {
    menuList: {
      type: Array
    }
  },
  data () {
    return {
      isCollapse: false,
      openName: [],
      activeName: ''
    };
  },
  watch: {
    $route: {
      handler (newName, oldName) {
        // this.activeName = `${newName.meta.parentName}/${newName.meta.fromName}`;
        this.openName = [newName.meta.parentName];
      },
      immediate: true,
    },
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.home-nav-menu {
  height: calc(100vh - 80px);
  position: relative;
}

.el-radio-group {
  position: absolute;
  z-index: 1;
  right: -15px;
}

.el-icon-s-fold,
.el-icon-s-unfold {
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  line-height: 30px;
  cursor: pointer;
}

::v-deep .el-submenu__title {
  text-align: left !important;
}

.el-menu-item {
  text-align: left;
  text-indent: 20px;
}

::v-deep .el-menu-item:focus,
.el-menu-item:hover {
  background: rgba(120, 163, 255, 0.4);
  border-right: 2px solid rgba(120, 163, 255, 0.7);
}

::v-deep .el-menu-item.is-active {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #507CE5;

}
</style>