<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { getToken } from './http/api/task'
export default {
  created () {
    // this.getToken()
  },
  methods: {
    async getToken () {
      try {
        let { code, body } = await getToken()
        if (code == 200) {
          localStorage.setItem("Token", body)
          // Vue.prototype.$cookies.set('usertoken', body)
        }
      } catch (error) { }
    },
  }
}

</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  height: 100vh;
}

html,
body,
p,
ul,
li,
h1 {
  margin: 0;
  padding: 0;
}
</style>
