/**
 * 文件描述: 采集器看板路由
 * @author 阿苏
 * @dTime  2022/09/19
 * @see    
 */
import Layout from '@/layout/layout.vue'
const routes = [
  {
    path: '/acquisitionBoard',
    name: 'acquisitionBoard',
    component: Layout,
    dispatch: '/list',
    meta: {
      showMenus: true,
      levelMenus: 1,
      icon: "el-icon-monitor",
      menuId: 6,
      title: "采集器管理"
    },
    children: [
      // 采集器看板-列表
      {
        path: 'list',
        name: 'list',
        meta: {
          showMenus: true,
          levelMenus: 2,
          parentName: 'acquisitionBoard',
          fromName: "list",
          menuId: 10,
          title: "采集器看板"
        },
        component: () => import('../../views/acquisitionBoard/list.vue'),
      },
      // 采集器看板-详情
      {
        path: 'details/:taskName/:activeName',
        name: 'acquisitionBoardDetails',
        meta: {
          showMenus: false,
          levelMenus: 2,
          parentName: 'acquisitionBoard',
          fromName: "acquisitionBoardDetails",
          title: "采集器看板详情"
        },
        component: () => import('../../views/acquisitionBoard/details.vue'),
      },
      // 采集器看板-心跳看板
      {
        path: 'heartbeatBoard',
        name: 'heartbeatBoard',
        meta: {
          showMenus: true,
          levelMenus: 2,
          menuId: 11,
          parentName: 'acquisitionBoard',
          fromName: "heartbeatBoard",
          title: "心跳看板"
        },
        component: () => import('../../views/acquisitionBoard/heartbeatBoard.vue'),
      },
      // 采集器看板-采集计数
      {
        path: 'acquisitionCount',
        name: 'acquisitionCount',
        meta: {
          showMenus: true,
          levelMenus: 2,
          menuId: 12,
          parentName: 'acquisitionBoard',
          fromName: "acquisitionCount",
          title: "采集计数"
        },
        component: () => import('../../views/acquisitionBoard/acquisitionCount.vue'),
      },
    ]
  },
]
export default routes